import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
  useLazyGetAllSubscriptionsQuery,
} from "../../services/subscription";
import { CommonBody, Subscription } from "../../types/General";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageSubscription = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getSubscriptionsData, { isLoading }] =
    useLazyGetAllSubscriptionsQuery();
  const [subscriptionStatus] = useChangeSubscriptionStatusMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [subsData, setSubsData] = useState<Subscription[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const translate=useTranslation();
  const getSubscriptions = async () => {
    try {
      const response = await getSubscriptionsData({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubsData(response?.data?.membership);
        setTotalCount(response?.data?.totalPages);
      } else {
        setSubsData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubscription({ subsId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Subscription deleted successfully");
        getSubscriptions();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    const response = await subscriptionStatus({
      encryptedBody,
      subsId: id,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = subsData?.map((x: any) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setSubsData(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Subscription Plans"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getSubscriptions();
  }, [page, debouncedSearchTerm]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translate?.main?.ManageSubscriptionPlan}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subscription/add")}
              >
              {translate?.main?.AddPlan}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translate?.main?.SNo}</TableCell>
                  <TableCell>{translate?.main?.PlanName}</TableCell>
                  <TableCell>{translate?.main?.Validity}</TableCell>
                  <TableCell>{translate?.main?.Price}</TableCell>
                  <TableCell>{translate?.main?.Description}</TableCell>
                  <TableCell>{translate?.main?.Status}</TableCell>
                  <TableCell>{translate?.main?.Action}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subsData?.length > 0 ? (
                  subsData?.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{row?.planName || "-"}</TableCell>
                      <TableCell>{row?.validity || "-"}</TableCell>

                      <TableCell>{"$" + " " + row?.price || "-"}</TableCell>
                      <TableCell>{row?.description || "-"}</TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateUserStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          {hidePermission?.isAdd ? (
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-subscription/edit/${row?._id}`
                                )
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : null}
                          {hidePermission?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Subscription Plan Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Subscription"
      />
    </MainContainer>
  );
};

export default ManageSubscription;
