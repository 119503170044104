import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Modal,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useChangeUserStatusMutation,
  useDeleteUserMutation,
  useLazyGetAllUsersQuery,
  useLazyGetUserCsvQuery,
  useUpdateUserMutation,
} from "../../services/users";
import { AllUser, CommonBody } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import { isNumber, isValidInput } from "../../utils/validations";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";
const ManageAgents = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getAllUser, { isLoading }] = useLazyGetAllUsersQuery();
  const [updateUser] = useChangeUserStatusMutation();
  const [addCredit] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [page, setPage] = useState(1);
  const [userCsv] = useLazyGetUserCsvQuery();
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [category, setCategory] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openQueryModal, setOpenQueryModal] = React.useState(false);
  const [id, setId] = useState<string>("");
  const [error, setError] = useState(false);
  const translate=useTranslation();
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const [normalCredit, setNormalCredit] = useState("");
  const [highlightedCredit, setHighlightedCredit] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClickOpen = (id: string) => {
    setOpenQueryModal(true);
    setId(id);
  };
  const handleClose = () => {
    setOpenQueryModal(false);
  };

  const getUsers = async () => {
    try {
      const response = await getAllUser({
        query: debouncedSearchTerm.trim(),
        page: page,
        type: "agent",
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.users || []);
        setTotalCount(response?.data?.totalCount);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUser({ userId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Agent deleted successfully");
        getUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    const response = await updateUser({ encryptedBody, userId: id }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = allUsers.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setAllUsers(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const getUserCsv = async () => {
    try {
      const response = await userCsv({
        type: "agent",
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleAddCredit = async () => {
    const normalCreditValue = Number(normalCredit);
    const highlightedCreditValue = Number(highlightedCredit);

    if (normalCreditValue <= 0 && highlightedCreditValue <= 0) {
      showError("Please fill in at least one credit field");
      return;
    }

    const data = {
      normalCred: normalCredit || 0,
      highlightCred: highlightedCredit || 0,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await addCredit({ userId: id, encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Credits added successfully");
        setOpenQueryModal(false);

        getUsers();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage Agents");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    getUsers();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.agents?.ManageAgents}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={() => getUserCsv()}>
                <FileDownloadIcon />  {translate?.agents?.ExportCsv}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> {translate?.agents?.SNo}</TableCell>

                  <TableCell> {translate?.agents?.Name}</TableCell>
                  <TableCell> {translate?.agents?.PhoneNo}</TableCell>
                  <TableCell> {translate?.agents?.Email}</TableCell>
                  <TableCell> {translate?.agents?.AccountStatus}</TableCell>
                  <TableCell> {translate?.agents?.Credits}</TableCell>
                  <TableCell> {translate?.agents?.Actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers?.length > 0 ? (
                  allUsers?.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>

                      <TableCell>{row?.fullName || "-"}</TableCell>

                      <TableCell>{`${row?.countryCode || ""} ${
                        row?.phone || "-"
                      }`}</TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>

                      <TableCell>
                        <Switch
                          {...label}
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateUserStatus(row?._id, !row?.isBlocked)
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        className="addCredits"
                        onClick={() => handleClickOpen(row?._id)}
                      >
                       {translate?.agents?.AddCredits}
                      </TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-agents/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {hidePermission?.isAdd ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-agents/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : null}
                          {hidePermission?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                       {translate?.agents?.NoAgentFound}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {allUsers?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={allUsers}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name={translate?.agents?.Agent}
      />
      <Dialog
        open={openQueryModal}
        onClose={handleClose}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography className="dialog_title">  {translate?.agents?.AddCredits}</Typography>

          <FormControl fullWidth>
            <Typography className="custom_label">  {translate?.agents?.NormalCredits}</Typography>
            <TextField
              hiddenLabel
              className="text_field"
              autoFocus
              name="normal"
              type="text"
              placeholder="Normal Credits"
              fullWidth
              onChange={(val) => {
                setNormalCredit(val.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ paddingTop: 2 }}>
            <Typography className="custom_label">
            {translate?.agents?.HighlightedCredits}
            </Typography>
            <TextField
              hiddenLabel
              className="text_field"
              autoFocus
              name="highlighted"
              type="text"
              placeholder="Highlighted Credits"
              fullWidth
              onChange={(val) => {
                setHighlightedCredit(val.target.value);
              }}
            />
          </FormControl>
          <Box className="form_btn">
            <Button className="btn" variant="outlined" onClick={handleClose}>
              {translate?.agents?.Cancel}
            </Button>
            <Button
              className="btn btn_primary"
              type="submit"
              onClick={handleAddCredit}
            >
              {translate?.agents?.Add}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageAgents;
