// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";
import {
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from "../../services/main";
import { showError, showToast } from "../../constants";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const EditCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const [getCategoryDetails] = useLazyGetCategoryByIdQuery();
  const [updateCategory] = useUpdateCategoryMutation();
  const [category, setCategory] = useState<string>("");
  const [categoryById, setCategoryById] = useState<any>();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const translate = useTranslation();
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res = await getCategoryDetails({ categoryId: id }).unwrap();
      if (res?.statusCode === 200) {
        setCategoryById(res?.data);
        setImage(res?.data?.image);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: categoryById?.name || "",
      title_ar: categoryById?.name_ar || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      title_ar: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        name: formik?.values?.title,
        name_ar: formik?.values?.title_ar,
        image: image,
      };

      if (id && id !== "add") {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateCategory({
            userId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Category updated successfully");
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateCategory({
            userId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-category Added Successfully");
            formik.resetForm();
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    getCategoryDetail(id);
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Category</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>

                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                    </div>
                  </Grid>
                  {state === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          className="select_div"
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="cat1">Category 1</MenuItem>
                          <MenuItem value="cat2">Category 2</MenuItem>
                          <MenuItem value="cat3">Category 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {state === "GOAL" ? (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Sub-category Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Sub-category Name"
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Category Name (english)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          disabled
                          variant="outlined"
                          value={formik.values.title}
                          fullWidth
                          placeholder="Category Name"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Category Name (arabic)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Category Name"
                          onBlur={formik.handleBlur}
                          value={formik.values.title_ar}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              formik.handleChange(val);
                            }
                          }}
                          helperText={
                            formik.touched.title_ar && formik.errors.title_ar
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default EditCategories;
