import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  useChangeTutorialStatusMutation,
  useDeleteTutorialMutation,
  useLazyGetTutorialByIdQuery,
  useLazyGetTutorialsQuery,
} from "../../services/tutorial";
import { Loader, showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const ManageTutorial = () => {
  const navigate = useNavigate();
  const [deleteTutorial] = useDeleteTutorialMutation();
  const [getTutorials, { isLoading }] = useLazyGetTutorialsQuery();
  const [tutorialStatus] = useChangeTutorialStatusMutation();
  //   const [deleteBannerById] = useLazyDeleteBannerIdQuery();

  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tutorialData, setTutorialData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const translate=useTranslation();
  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getSubAdmin = async () => {
    try {
      const response = await getTutorials({
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setTutorialData(response?.data?.tutorial || []);
        setTotalCount(response?.data?.count);
      } else {
        setTutorialData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    const response = await tutorialStatus({
      encryptedBody,
      tutorialId: id,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = tutorialData?.map((x: { _id: string }) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      tutorialData(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteTutorial({ tutorialId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Tutorial deleted successfully.");
        getSubAdmin();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubAdmin();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManageTutorials}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-tutorials/add")}
              >
             {translate?.main?.AddTutorial}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translate?.main?.SNo}</TableCell>
                  <TableCell align="center">{translate?.main?.VideoTitle}</TableCell>
                  <TableCell align="center">{translate?.main?.Description}</TableCell>

                  <TableCell align="center">{translate?.main?.Status}</TableCell>
                  <TableCell align="center">{translate?.main?.Action}</TableCell>
                </TableRow>
              </TableHead>
              {tutorialData?.length ? (
                tutorialData?.map((row: any, i: any) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell align="center">
                          {row?.title || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.description || "-"}
                        </TableCell>

                        {/* <TableCell align="center">20/09/2023</TableCell>
                  <TableCell align="center">30/09/2023</TableCell> */}
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {/* {hidePermission?.isAdd ? ( */}
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-tutorials/edit/${row?._id}`,
                                    {
                                      state: { type: "edit" },
                                    }
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                            {/* // ) : null} */}
                            {/* {hidePermission?.isDelete ? ( */}
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            {/* ) : null} */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={6}>
                   {translate?.main?.NoTutorialFound}
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        {tutorialData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={tutorialData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Tutorial"
      />
    </MainContainer>
  );
};

export default ManageTutorial;
