import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const EditSubscriptionPlan = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");
  const translate=useTranslation();
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      id: 1,
      name: "Feature 1",
      enable: true,
    },
    {
      id: 2,
      name: "Feature 2",
      enable: true,
    },
    {
      id: 3,
      name: "Feature 3",
      enable: true,
    },
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.EditSubscriptionPlan}</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
           {translate?.main?.Back}
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">{translate?.main?.PlanName}</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder={translate?.main?.PlanName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                  {translate?.main?.PlanValidity}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiver}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChange}
                    >
                      <MenuItem value="" disabled>
                      {translate?.dashboard?.Select}
                      </MenuItem>
                      <MenuItem value="All">  {translate?.dashboard?.Monthly}</MenuItem>
                      <MenuItem value="User 1">Quarterly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">  {translate?.main.PlanPrice}</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder={translate?.main.PlanPrice}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Price for one credit
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Price"
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">{translate?.main.Description}</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="firstName"
                    fullWidth
                    multiline
                    placeholder="Description"
                    minRows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                     {translate?.main.PlanFeatures}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">{translate?.main.SNo}</TableCell>
                          <TableCell>  {translate?.main.Feature}</TableCell>
                          <TableCell> {translate?.main.Status}</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch {...label} defaultChecked size="small" />
                            </TableCell>
                            {/* <TableCell>
                              <Box className="table_actions">
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                {translate?.main.Save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default EditSubscriptionPlan;
