import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import WarnModal from "../../components/WarnModal";
import {
  useLazyGetCategoriesQuery,
  useChangeCategoryStatusMutation,
} from "../../services/main";
import { isValidInput } from "../../utils/validations";
import { Loader, showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageCategories = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getCategories, { isLoading }] = useLazyGetCategoriesQuery();
  const [updateCategory] = useChangeCategoryStatusMutation();
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [category, setCategory] = useState<any>([]);
  const [page, setPage] = useState(1);
  const translate = useTranslation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getCategory = async () => {
    try {
      const response = await getCategories({
        query: debouncedSearchTerm.trim(),
        page: 1,
        type: 1,
        size: 10,
        categoryId: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.category || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    const response = await updateCategory({
      encryptedBody,
      userId: id,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = category.map((x: any) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setCategory(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getCategory();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManageCategories}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              {hidePermission?.isAdd ? (
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-categories/add")}
                  >
                    {translate?.main?.AddSubcategory}
                  </Button>
                </Box>
              ) : null}
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{translate?.main?.SNo}</TableCell>
                    <TableCell>{translate?.main?.Image}</TableCell>
                    <TableCell>{translate?.main?.CategoryName}</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell>{translate?.main?.SubCategory}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category?.length > 0 ? (
                    category?.map((row: any, i: any) => (
                      <TableRow key={row?._id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img
                              src={
                                row?.image
                                  ? row?.image
                                  : "/static/images/image_placeholder.jpeg"
                              }
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name || "-"}</TableCell>
                        {/* <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell> */}
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-categories/sub-categories/${row?._id}`
                                )
                              }
                            >
                              <LibraryAddIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                navigate(`/edit-categories/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {translate?.main?.NoCategoryFound}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        {category?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={category}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>

      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => {}}
        name="Category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
