import { CategoryOutlined } from "@mui/icons-material";
import { END_POINTS } from "../constants/url";
import { AllUser, CommonBody, Property } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetPropertyParams = {
  page: number;
  query: string;
};

type PutBlockUserParams = {
  tutorialId: string;
  encryptedBody: CommonBody;
};

export const tutorialApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTutorials: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          pages: number;
          tutorial: any;
        };
      },
      GetPropertyParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.tutorial + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),

    postAddTutorial: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.tutorial,
        method: "POST",
        body,
      }),
    }),

    updateTutorial: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, tutorialId }) => ({
        url: `${END_POINTS.tutorial}/${tutorialId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteTutorial: builder.mutation<
      CommonResponseType,
      { tutorialId: string }
    >({
      query: ({ tutorialId }) => ({
        url: `${END_POINTS.tutorial}/${tutorialId}`,
        method: "DELETE",
      }),
    }),

    getTutorialById: builder.query<
      CommonResponseType & { data: any },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.tutorial}/${subId}`,
        method: "GET",
      }),
    }),

    changeTutorialStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, tutorialId }) => ({
        url: `${END_POINTS.tutorial}/${tutorialId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const {
  useLazyGetTutorialsQuery,
  usePostAddTutorialMutation,
  useUpdateTutorialMutation,
  useDeleteTutorialMutation,
  useLazyGetTutorialByIdQuery,
  useChangeTutorialStatusMutation,
} = tutorialApi;
