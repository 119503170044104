import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MainContainer from "../../layout/MainContainer";
import "../../pages/users/Users.scss";
import { useLazyGetPropertyByIdQuery } from "../../services/property";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Property } from "../../types/General";
import { Loader, showError } from "../../constants";
import { is } from "immer/dist/internal";
import useTranslation from "../../hooks/Translation";

const PropertyDetails = () => {
  const { id } = useParams();
  const [myImages, setMyImages] = useState<any>([]);
  console.log(myImages);
  const navigate = useNavigate();
  const [open_gallery, setOpen_gallery] = useState(false);
  const [propertyDetails, { isLoading }] = useLazyGetPropertyByIdQuery();
  const [propertyData, setPropertyData] = useState<Property>();
  const translate=useTranslation();
  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await propertyDetails({ propertyId: id }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUserDetailById(id);
  }, []);

  useEffect(() => {
    if (propertyData) {
      if (propertyData?.image?.length) {
        if (propertyData?.image?.length && myImages?.length === 0) {
          let newObj = propertyData?.image?.map((item: any) => {
            return {
              type: item.endsWith(".mp4") ? "video" : "image",
              file: "File" as any,
              url: item as string,
            };
          });
          setMyImages([...myImages, ...newObj]);
        }
      }
    }
  }, [propertyData]);

  return (
    <MainContainer>
      <Loader isLaod={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Property Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-properties");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Name</Typography>
                      <Typography component="p">
                        {propertyData?.subCategoryId?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Category</Typography>
                      <Typography component="p">
                        {propertyData?.categoryId?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Location</Typography>
                      <Typography component="p">
                        {propertyData?.subAreaId?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Status</Typography>
                      <Typography component="p">
                        {propertyData?.isBlocked == true
                          ? "Inactive"
                          : "Active"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            About the property
                          </Typography>
                          <Typography component="p">
                            {propertyData?.description || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Area</Typography>
                          <Typography component="p">
                            {Number(propertyData?.totalArea).toLocaleString() +
                              " " +
                              "mt sq" || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Agent Name</Typography>
                          <Typography component="p">
                            {propertyData?.userId?.fullName || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Map link</Typography>
                          <p
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(propertyData?.mapLink, "_blank");
                            }}
                          >
                            {propertyData?.mapLink || "-"}
                          </p>
                        </Box>
                      </Grid>

                      {/* <Grid item mt={2} xs={12} className="view_box_list">
                        <h3>Images & Videos</h3>
                        <div
                          style={{ display: "flex", gap: 30, flexWrap: "wrap" }}
                        >
                          {myImages?.length ? (
                            <>
                              {myImages?.map((item: any, i: any) => {
                                return (
                                  <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="upload_document w_50"
                                    key={i}
                                  >
                                    <div className="inner2">
                                      {item.type === "image" ? (
                                        <figure>
                                          <img src={item.url} alt="upload" />
                                        </figure>
                                      ) : item.type === "video" ? (
                                        <video
                                          width="100%"
                                          height="100%"
                                          controls
                                        >
                                          <source
                                            src={item.url}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : null}
                                    </div>
                                  </Grid>
                                );
                              })}
                            </>
                          ) : null}
                        </div>
                      </Grid> */}
                      {myImages?.length ? (
                        <Grid item xs={12}>
                          <Box>
                            <h3>Images & Videos</h3>
                          </Box>
                          <Box className="other_gallery">
                            <figure>
                              {myImages[0]?.type === "image" ? (
                                <figure>
                                  <img src={myImages[0]?.url} alt="upload" />
                                </figure>
                              ) : myImages[0]?.type === "video" ? (
                                <video width="100%" height="100%" controls>
                                  <source
                                    src={myImages[0]?.url}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : null}
                              <span
                                className="more"
                                onClick={() => setOpen_gallery(true)}
                              >
                                <img src="/images/icon_image.svg" alt="" />
                                Show all images
                              </span>
                            </figure>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>

      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpen_gallery(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {myImages?.length
                  ? myImages?.map((item: any, i: any) => (
                      <SwiperSlide key={i}>
                        {item.type === "image" ? (
                          <figure>
                            <img src={item.url} alt="upload" />
                          </figure>
                        ) : item.type === "video" ? (
                          <video controls>
                            <source src={item.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : null}
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </MainContainer>
  );
};

export default PropertyDetails;
