import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { Button, InputField } from "../../components";
import { Colors } from "../../assets";
import useTranslation from "../../hooks/Translation";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { setCredentials } from "../../reducers/authSlice";

import { useAppDispatch } from "../../hooks/store";
import { usePostLoginMutation } from "../../services/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SavingsIcon from "@mui/icons-material/Savings";
import PaymentIcon from "@mui/icons-material/Payment";
import BarChartIcon from "@mui/icons-material/BarChart";
import LabelIcon from "@mui/icons-material/Label";
import CategoryIcon from "@mui/icons-material/Category";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";

import MapIcon from "@mui/icons-material/Map";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deviceToken = getFromStorage(STORAGE_KEYS.fcmToken);
  console.log(deviceToken, "FDSFSF");
  const Translation = useTranslation() as any;
  const [showPassword, setShowPassword] = useState(false);
  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const items = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      name: "Manage Users",
      icon: <PersonAddIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      name: "Manage Agents",
      icon: <PeopleIcon />,
      key: "3",
      selected: "/manage-agents",
    },
    {
      name: "Manage Sub-Admin",
      icon: <PeopleIcon />,
      key: "4",
      selected: "/manage-subAdmin",
    },
    {
      name: "Manage Subscription Plans",
      icon: <SubscriptionsIcon />,
      key: "5",
      selected: "/manage-subscription",
    },
    {
      name: "Manage Categories",
      icon: <CategoryIcon />,
      key: "6",
      selected: "/manage-categories",
    },
    {
      name: "Manage Properties",
      icon: <MapsHomeWorkIcon />,
      key: "7",
      selected: "/manage-properties",
    },
    {
      name: "Manage Areas",
      icon: <MapIcon />,
      key: "8",
      selected: "/manage-areas",
    },
    // {
    //   name: "Manage Labels",
    //   icon: <LabelIcon />,
    //   key: "8",
    //   selected: "/manage-labels",
    // },
    {
      name: "Manage Tutorials",
      icon: <PhotoCameraFrontIcon />,
      key: "9",
      selected: "/manage-tutorials",
    },
    {
      name: "Manage Payments",
      icon: <PaymentIcon />,
      key: "10",
      selected: "/manage-payments",
    },
    {
      name: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "11",
      selected: "/manage-notifications",
    },

    {
      name: "Analytics",
      icon: <BarChartIcon />,
      key: "12",
      selected: "/analytics",
    },
    {
      name: "Settings",
      icon: <SummarizeTwoToneIcon />,
      key: "13",
      selected: "/cms",
    },
    {
      name: "Manage FAQs",
      icon: <BarChartIcon />,
      key: "14",
      selected: "/faq",
    },
  ];

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const data = {
        key: values.email,
        password: values.password,
        deviceType: "WEB",
        deviceToken: deviceToken,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

        const response = await loginMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          console.log(response?.data?.permission);
          showToast("Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          if (response?.data?.role === "SUBADMIN") {
            const permission = response?.data?.permission;

            if (response?.data?.role === "SUBADMIN" && permission?.length > 0) {
              const firstPermissionLabel = permission[0]?.name;
              const redirectPath = items.find(
                (item) => item.name === firstPermissionLabel
              )?.selected;

              if (redirectPath) {
                navigate(redirectPath);
              } else {
                console.error("Invalid permission label for redirection");
                // Handle the case where the label doesn't match any item in the array
              }
            }
          } else {
            // Default redirect for other roles or when no permissions are available
            navigate("/dashboard");
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setToStorage(STORAGE_KEYS.language, "en");
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        ></Box>
        <div className="auth_box">
          {/* <div className="logo_div">
            <figure className="logo_cntnr">
              <img src="/static/images/logogif.gif" alt="" />
            </figure>
          </div> */}
          <div className="form_div">
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 2, textAlign: "center", color: Colors.primary }}>
                <Typography variant="h5">{Translation.auth.login}</Typography>
              </Box>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">
                  {Translation.auth.emailId}
                </Typography>
                <InputField
                  name="email"
                  placeholder={Translation.auth.emailId}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">
                  {Translation.auth.password}
                </Typography>
                <InputField
                  name="password"
                  placeholder={Translation.auth.password}
                  password
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="remember_box">
                  <FormControlLabel
                    control={<Checkbox />}
                    label={Translation.auth.remember}
                    checked={formik.values.remember}
                    name="remember"
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box
                  className="anchor_link"
                  onClick={() => navigate("/forgot-password")}
                >
                  <Typography>{Translation.auth.forgotPassword}?</Typography>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Button value={Translation.auth.login} />
              </Box>
            </form>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
