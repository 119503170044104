import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { showError, showToast } from "../constants";
import useAuth from "../hooks/useAuth";
import {
  useAddFaqsMutation,
  useDeleteFaqsMutation,
  useLazyGetAllFaqsQuery,
} from "../services/main";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import useTranslation from "../hooks/Translation";

const MainFaq = () => {
  const userData = useAuth();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionAr, setQuestionAr] = useState("");
  const [answerAr, setAnswerAr] = useState("");
  const [faqs, setFaqs] = useState<any>();
  const [getAllFaqs, { isLoading }] = useLazyGetAllFaqsQuery();
  const [addFaqsMutation] = useAddFaqsMutation();
  const [deleteFaqs] = useDeleteFaqsMutation();
  const [removedItemId, setRemovedItemId] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
const translate=useTranslation();
  const getAllFaqsHandler = async () => {
    try {
      const res = await getAllFaqs({}).unwrap();
      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleAddFaq = async () => {
    const newFaq = {
      question: question,
      answer: answer,
      question_ar: questionAr,
      answer_ar: answerAr,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(newFaq) as CommonBody;
      const res = await addFaqsMutation(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        getAllFaqsHandler();
        showToast("FAQ added successfully");
        setQuestion("");
        setAnswer("");
        setQuestionAr("");
        setAnswerAr("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDeleteFaq = async (id: string) => {
    try {
      const res = await deleteFaqs({ faqId: id }).unwrap();
      if (res?.statusCode === 200) {
        setRemovedItemId(id);
        getAllFaqsHandler();
        showToast("FAQ deleted successfully");
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Subscription Plans"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getAllFaqsHandler();
  }, []);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <Grid container spacing={2}>
      {faqs?.map((item: any, index: any) => {
        return (
          <Grid key={index} item xs={12}>
            <Box className="faq_box">
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">
                {translate?.main?.QuestionEnglish}
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.question}
                  disabled
                  placeholder="Question ( English )"
                ></TextField>
                <Typography className="custom_label" sx={{ pt: 2 }}>
                {translate?.main?.QuestionArabic}
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  disabled
                  value={item?.question_ar}
                  placeholder="Question ( Arabic )"
                ></TextField>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Typography className="custom_label">
                {translate?.main?.AnswerEnglish}
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  disabled
                  variant="outlined"
                  value={item?.answer}
                  placeholder="Answer ( English )"
                ></TextField>
                <Typography className="custom_label" sx={{ pt: 2 }}>
                {translate?.main?.AnswerArabic}
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  disabled
                  variant="outlined"
                  value={item?.answer_ar}
                  placeholder="Answer ( Arabic )"
                ></TextField>
              </FormControl>
              <Box className="faq_btn">
                {hidePermission?.isDelete ? (
                  <Button
                    variant="contained"
                    className="btn btn_primary sm"
                    onClick={() => handleDeleteFaq(item?._id)}
                    disabled={removedItemId === item?._id}
                  >
                  {translate?.main?.Remove}
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">
            {translate?.main?.QuestionEnglish}
            </Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={question}
              onChange={(val) => setQuestion(val.target.value)}
              placeholder= {translate?.main?.QuestionEnglish}
            ></TextField>
            <Typography className="custom_label" sx={{ pt: 2 }}>
            {translate?.main?.QuestionArabic}
            </Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={questionAr}
              onChange={(val) => setQuestionAr(val.target.value)}
              placeholder=  {translate?.main?.QuestionArabic}
            ></TextField>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label"> {translate?.main?.AnswerEnglish}</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={answer}
              onChange={(val) => setAnswer(val.target.value)}
              placeholder= {translate?.main?.AnswerEnglish}
            ></TextField>
            <Typography className="custom_label" sx={{ pt: 2 }}>
            {translate?.main?.AnswerArabic}
            </Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={answerAr}
              onChange={(val) => setAnswerAr(val.target.value)}
              placeholder={translate?.main?.AnswerArabic}
            ></TextField>
          </FormControl>
          <Box className="faq_btn">
            {hidePermission?.isAdd ? (
              <Button
                variant="contained"
                disabled={!question || !answer || !questionAr || !answerAr}
                className="btn btn_primary sm"
                onClick={handleAddFaq}
              >
                 {translate?.main?.Add}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainFaq;
