import { createTheme } from "@mui/material";
import { Colors } from "../assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#151D30",
    },
    secondary: {
      main: "#1c6975",
    },
    info: {
      main: "#0090B7",
    },
    success: {
      main: "#00C9A5",
    },
    error: {
      main: "#E3597A",
    },
    warning: {
      main: "#F9F871",
    },
  },
});
export default theme;
