import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useChangeSubAdminStatusMutation,
  useDeleteSubAdminMutation,
  useLazyGetAllSubAdminsQuery,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { CommonBody, SubAdmin } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import WarnModal from "../../components/WarnModal";
import useTranslation from "../../hooks/Translation";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const [getAllSubAdmin] = useLazyGetAllSubAdminsQuery();
  const [updateStatus] = useChangeSubAdminStatusMutation();
  const [deleteSubAdmin] = useDeleteSubAdminMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [subAdminData, setSubAdminData] = useState<SubAdmin[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const translate=useTranslation();
  const getSubAdmin = async () => {
    setLoading(true);
    try {
      const response = await getAllSubAdmin({
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSubAdminData(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubAdminData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      setLoading(false);
    }
  };

  const updateSubAdminStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    const response = await updateStatus({
      userId: id,
      encryptedBody,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = subAdminData.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setSubAdminData(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubAdmin({ subAdminId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Sub-admin deleted successfully.");
        getSubAdmin();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  useEffect(() => {
    getSubAdmin();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translate?.main?.ManageSubAdmin}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                 {translate?.main?.addSubAdmin}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">  {translate?.main?.SNo}</TableCell>
                  <TableCell>  {translate?.main?.Name}</TableCell>
                  <TableCell>  {translate?.main?.Email}</TableCell>
                  <TableCell>  {translate?.main?.Phone}</TableCell>
                  <TableCell>  {translate?.main?.Status}</TableCell>
                  <TableCell>  {translate?.main?.Action}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subAdminData?.length > 0 ? (
                  subAdminData.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.firstName || "-"}</TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>
                        {row?.countryCode + " " + row?.phone || "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          defaultChecked
                          size="small"
                          onChange={() =>
                            updateSubAdminStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-subAdmin/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                    {translate?.main?.Nosubadminsfound}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Sub-Admin"
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
