export const en = {
  globals: {
    save: "save",
    submit: "submit",
    back: "back",
    select: "Select",
    serialNo: "S.No",
    title: "Title",
    description: "Description",
    price: "Price",
    status: "Status",
    actions: "Actions",
    name: "Name",
    normalCred: "Normal Credits",
    highlightedCred: "Highlighted Credits",

  },
  auth: {
    emailId: "Email Id",
    password: "Password",
    remember: "Remember me",
    forgotPassword: "Forgot Password",
    oldPass: "Old Password",
    confmPas: "Confirm Password",
    otpVerification: "OTP Verification",
    verify: "Verify",
    resendOtp: "Resend Otp",
    login: "Sign In",
    resendPass: "Reset Password",
    editProfile: "Edit Profile",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
  },
  main: {
    image: "Image",
    addCategory: "Add Category",
    editCategory: "Edit Category",
    catName: "Category Name",
    selectCat: "Select Category",
    address: "Address",
    addSubAdmin: "Add Sub-Admin",
    addEdit: "Add/Edit",
    accountStatus: "Account Status",
    viewUser: "View Customer",
    editCustomer: "Edit Customer",
    question: "Question",
    answer: "Answer",
    contactSupport: "Contact Support",
    privacyPolicy: "Privacy Policy",
    aboutUs: "About Us",
    terms: "Terms And Conditions",
    changeLang: "Change language",
    notification: "Notification",
    showing: "Showing",
    items: "items",
    active: "Active",
    Inactive: "Inactive",
    subscription: "Subscription",
    message: "Message",
    receiver: "Receiver",
    broadcast: "Broadcast",
    atLeast1Feature: "At least one Feature must be selected",
    loginToRe: "Log in to Real Estate Admin",
    date: "Date",
    credits: "Credits",
    addCredits: "Add Credits",
    export: "Export CSV",
    subCat: "Sub-Category",
    addSubCat: "Add Sub-Category",
    governorate: "Governorate",
    propertyName: "Property Name",
    confirmDelete:" Are you sure you want to delete this ",
    Yes:"Yes",
    No:"No",
    Back:"Back",
    SNo:"S.No",
    Delete:"Delete",
    Title:"Title",
    Action:"Action",
    Status:"Status",
    Description:"Description",
    Price:"Price",
    AcountStatus:"Acount Status",
    Name:"Name",
    Email:"Email",
    PhoneNo:"Phone No",
    TotalCredits:"Total credits",
    TotalCreditsLeft:"Total credits left",
    TotalCreditsPending:"Total credits pending",
    PlanPrice:"Plan Price",
    PlanName:"Plan Name",
    NormalListingCredits:"Normal Listing Credits",
    HighlightedListingCredits:"Highlighted Listing Credits",
    PlanDetails:"Plan Details",
    Governorate:"Governorate",
    NoAreaFound:"No Area Found",
    AddGovernorate:"Add Governorate",
    ManageAreas:"Manage Areas",
    Totalcreditsused:"Total credits used",
    ManageSubscriptionPlan:"Manage Subscription Plan",
    AddPlan:" Add Plan",
    Image:"Image",
    CategoryName:"Category Name",
    SubCategory:"Sub-Category",
    NoCategoryFound:"No Category Found",
    ManageCategories:"Manage Categories",
    AddSubcategory:"Add Sub-category",
    ManageSubAdmin:"Manage Sub-Admin",
    Phone:"Phone",
    Nosubadminsfound:" No sub-admins found.",
    Add:"Add",
    PropertiesforSale:"Properties for Sale",
    PropertiesforRent:"Properties for Rent",
    PropertiesforExchange:"Properties for Exchange",
    MostViewedProperties:"Most Viewed Properties",
    MostSearchedProperties:"Most Searched Properties",
    Pleaseselectcategory:"Please select category",
    Type:"Type",
    Location:"Location",
    CustomerName:"Customer Name",
    AgentName:"	Agent Name",
    PropertyCategory:"Property Category",
    PropertyName:"Property Name",
    NoPropertiesFound:" No Properties Found",
    Mostsearchedbyarea:" Most searched by area",
    UserPreferences:"User Preferences",
    All:"All",
    ManageProperties:"Manage Properties",
    VideoTitle:"Video Title",
    AddTutorial:"  Add Tutorial",
    NoTutorialFound:" No Tutorial Found",
    ManageTutorials:"Manage Tutorials",
    ManagePayments:"Manage Payments",
    PaymentMode:"Payment Mode",
    PaymentType:"Payment Type",
    Amount:"Amount",
    TotalEarnings:"Total Earnings",
    ReportsandAnalytics:"Reports and Analytics",
    EditProfile:"Edit Profile",
    Save:"Save",
    PhoneNumber:"Phone Number",
    FirstName:"First Name",
    LastName:"Last Name",
    FullName:"Full Name",
    EditAgentProfile:"Edit Agent Profile",
    EditTutorial:"Edit Tutorial",
    UploadVideos:" Upload Videos",
    DescriptionArabic:"Description (Arabic)",
    TitleArabic:"Title (Arabic)",
    ContactSupport:"Contact Support",
    PrivacyPolicy:"Privacy Policy",
    TermsConditions:"Terms & Conditions",
    CreditPrice:"Credit Price",
    ManageSettings:"Manage Settings",
    WhatsappPhoneNumber:" Whatsapp Phone Number",
    PrivacyPolicyEnglish:"Privacy Policy ( English )",
    Pleaseenterprivacypolicy:"Please enter privacy policy",
    PrivacyPolicyArabic:"Privacy Policy ( Arabic )",
    PleaseenterTermsandConditions:"Please enter Terms and Conditions",
    TermsConditionsArabic:"Terms & Conditions ( Arabic )",
    TermsConditionsEnglish:"Terms & Conditions ( English )",
    NormalCredits:" Normal Credits",
    HighlightedCredits:"  Highlighted Credits",
    TotalUsers:" Total Users",
    ManageNotifications:"Manage Notifications",
    AddNotification:" Add Notification",
    NoNotificationFound:"No Notification Found",
    EditUserProfile:"Edit User Profile",
    ManageFaqs:"Manage Faqs",
    QuestionEnglish:"    Question ( English )",
    QuestionArabic:"    Question ( Arabic )",
    AnswerEnglish:"Answer (English)",
    AnswerArabic:"Answer (Arabic)",
    Remove:"Remove",
    Arabic:"Arabic",
    English:"English",
    PaymentDetails:"Payment Details",
    SubscriptionPlan:"Subscription Plan",
    NotificationTitle:"   Notification Title",
    SelectReceiver:"  Select Receiver",
    Edit:"Edit",
    Module:"Module",
    View:"View",
    EditSubscriptionPlan:"Edit Subscription Plan",
    PlanValidity :"Plan Validity",
    PlanFeatures:"   Plan Features",
    Feature:"Feature",
    AddSubscriptionPlan:"Add Subscription Plan",
    EditSubAdmin:"Edit Sub-Admin",
    AddSubAdmin:"Add Sub-Admin",
    Showrequesttoagents:"Show request to agents",
    ManageRequest:"Manage Request",
    Month:"Month",
    Months:"Months",
    PleaseselectValidity:"Please select Validity",
    Category:"Category",
    NoSubcategoryFound:" No Sub-category Found",
    ManageSubCategory:"Manage Sub-Category",
    Validity:"Validity",
    Area:"Area",
    Please:"Please",
    Total:"Total",
    Map:"Map",
    Link:"Link",
    Agent:"Agent",
    For:"For",
Normal:"Normal",
Hightlighted:"Hightlighted",
Upload:"Upload",
Property:"Proprty",
Yourbrowserdoesnotsupportthevideotag:" Your browser does not support the video tag.",
Totalareainmetersquare:"Total area in meter square"
   }, 
  dashboard: {
    dashboard: "Dashboard",
    totalUsers: "Total Users",
    totalProperty: "Total Properties",
    forSale: "Properties for Sale",
    forRent: "Properties for Rent",
    forExchange: "Properties for Exchange",
    earnings: "Total Earnings",
    avgEarnings: "Avg. Monthly Earnings",
    byArea: "Properties Searched by Area",
    Earning:"Eranings",
    User:"Users",
    Property:"Properties",
    ExportCsv:"Export Csv",
    Hourly:"Hourly",
    Weekly:"Weekly",
    Monthly:"Monthly",
    Yearly:"Yearly",
    Select:"Select",
    
  },
  sideBar: {
    dashboard: "Dashboard",
    subAdmin: "Manage Sub-Admins",
    user: "Manage Users",
    agent: "Manage Agents",
    plans: "Manage Subscription Plans",
    category: "Manage Categories",
    property: "Manage Properties",
    areas: "Manage Areas",
    tutorial: "Promotional Tutorials",
    payments: "Manage Payments",
    noti: "Manage Notifications",
    reports: "Reports and Analytics",
    faqs: "Manage FAQs",
    setting: "Settings",
    notifications:"Manage Notifications",
    analytics:"Analytics"
  },
  error: {
    required: "This field is required",
    validation1: "Please select a valid video",
    maxValidation: "Maximum 80 character are allowed",
    minValidation: "Minimum 3 characters are required",
    noData: "No results found",
    titlereq: "Title is required",
    descReq: "Description is required",
    noFaq: "No faq's listing found",
    emailReq: "Email is required!",
    validEmail: "Enter a valid email address!",
    firstReq: "First name is required",
    lastReq: "Last name is required",
    phoneReq: "Phone number is required",
    minPhone: "Phone number must be at least 6 digits",
    maxPhone: "Phone number must be at least 16 digits",
    subAdminCreated: "Sub-admin created successfully",
    subAdminUpdate: "Sub-admin updated successfully",
    userUpdate: "User updated successfully",
    statusUpdate: "Status updated successfully",
    subAdminDel: "Sub-admin deleted successfully",
    userDel: "User deleted successfully",
    max100: "Maximum 100 character are allowed",
    max250: "Maximum 250 character are allowed",
    max500: "Maximum 500 character are allowed",
    catAdd: "Category added successfully",
    catUpdate: "Category updated successfully",
    settingUpdate: "Settings updated successfully",
    faqAdd: "FAQ added successfully",
    faqDel: "FAQ deleted successfully",
    cmsUpdate: "CMS updated successfully",
    messageReq: "Message is required",
    notiAdd: "Notification added successfully",
    notiDel: "Notification deleted successfully",
    logutReq: "Are you sure, you want to logout?",
    
  },
  plans: {
    planName: "Plan Name",
    planValid: "Plan Validity",
    planPrice: "Plan Price",
    feature: "Features",
  },
  user:{
    Actions:"Actions",
    Credits:"Credits",
    AccountStatus:"Account Status",
    Email:"Email",
    PhoneNo:"Phone No",
    Name:"Name",
    SNo:"S.No",
    ManageUsers:"Manage Users",
    ExportCsv:"Export CSV",
    SearchHere:"Search Here",
    Showing:"Showing",
    items:"items",
    AddCredits:"Add Credits",
    HighlightedCredits:" Highlighted Credits",
    NormalCredits:"Normal Credits",
    ViewUserProfile:"View User Profile"
  },
  agents:{
    Actions:"Actions",
    Credits:"Credits",
    AccountStatus:"Account Status",
    Email:"Email",
    PhoneNo:"Phone No",
    Name:"Name",
    SNo:"S.No",
    ManageAgents:"Manage Agents",
    ExportCsv:"Export CSV",
    SearchHere:"Search Here",
    Showing:"Showing",
    items:"items",
    AddCredits:"Add Credits",
    HighlightedCredits:" Highlighted Credits",
    NormalCredits:"Normal Credits",
    NoAgentFound:"No Agent Found",
    Cancel:"Cancel",
    Add:"Add",
    Agent:"Agent",
    ViewAgentProfile:"View Agent Profile"
  }
};
