import { useEffect, useState } from "react";

import * as Yup from "yup";
import { Box, Container, Typography } from "@mui/material";
import {
  usePostVerifyOtpMutation,
  usePostForgotPasswordMutation,
} from "../../../services/auth";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OtpInput from "react-otp-input";
import { Button } from "../../../components";
import { Colors } from "../../../assets";
import useTranslation from "../../../hooks/Translation";
import { useAppDispatch } from "../../../hooks/store";
import { temporaryToken } from "../../../reducers/authSlice";
import {
  Loader,
  setToStorage,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../../constants";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const Translation = useTranslation() as any;
  const location = useLocation();
  const { state } = location;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [verifyOptMutation] = usePostVerifyOtpMutation();
  const [forgotPasswordMutation] = usePostForgotPasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [countDown, setCountDown] = useState<number>(59);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(0);
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (otp?.length === 4) {
      setError(false);

      const data = {
        key: state?.email,
        code: otp,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response: any = await verifyOptMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setLoading(false);
          setToStorage(STORAGE_KEYS.tempToken, response?.data?.token);
          dispatch(temporaryToken({ tempToken: response?.data?.token }));
          navigate("/reset-password", { replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    let data = {
      email: state?.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await forgotPasswordMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={loading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        ></Box>
        <div className="auth_box">
          <div className="form_div">
            {/* <form> */}
            <Box sx={{ mb: 2, textAlign: "center", color: Colors.primary }}>
              <Typography variant="h5">
                {Translation.auth.otpVerification}
              </Typography>
            </Box>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button value={Translation.auth.verify} onClick={handleSubmit} />
            </Box>

            {countDown === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  pt: 2,
                }}
              >
                <Typography
                  className="anchor_link"
                  sx={{ cursor: "pointer" }}
                  onClick={handleResendOtp}
                >
                  Resend Otp
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verification code will expire in{" "}
                  <p style={{ margin: 0, color: "#D63853", paddingLeft: 3 }}>
                    00 : {countDown < 10 ? `0${countDown}` : countDown}
                  </p>
                </Typography>
              </Box>
            )}

            {/* </form> */}
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
