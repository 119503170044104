import { CategoryOutlined } from "@mui/icons-material";
import { END_POINTS } from "../constants/url";
import { AllUser, CommonBody, Property } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetPropertyParams = {
  page: number;
  query: string;
  type: number;
  preference: number;
  areaId: string;
};

type GetCsvParams = {
  query: string;
  type: number;
  preference: number;
  areaId: string;
};

type PutBlockUserParams = {
  propertyId: string;
  encryptedBody: CommonBody;
};

export const propertyApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          pages: number;
          property: Property[];
        };
      },
      GetPropertyParams
    >({
      query: ({ page, query, type, preference, areaId }) => ({
        url:
          END_POINTS.property +
          "?search=" +
          query +
          "&page=" +
          page +
          "&type=" +
          type +
          "&preference=" +
          preference +
          "&areaId=" +
          areaId,

        method: "GET",
      }),
    }),

    getPropertyById: builder.query<
      CommonResponseType & { data: Property },
      { propertyId: string | undefined }
    >({
      query: ({ propertyId }) => ({
        url: `${END_POINTS.property}/${propertyId}`,
        method: "GET",
      }),
    }),

    deleteProperty: builder.mutation<
      CommonResponseType,
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({
        url: `${END_POINTS.property}/${propertyId}`,
        method: "DELETE",
      }),
    }),

    getArea: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          pages: number;
          data: any;
        };
      },
      { page: number }
    >({
      query: ({ page }) => ({
        url: END_POINTS.area + "?page=" + page + "&limit=50",

        method: "GET",
      }),
    }),

    getParentArea: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          pages: number;
          data: any;
        };
      },
      { page: number; areaId: string; size: number }
    >({
      query: ({ page, areaId, size }) => ({
        url: END_POINTS.area + "/" + areaId + "/" + "?size=" + size,

        method: "GET",
      }),
    }),

    postAddProperty: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.property,
        method: "POST",
        body,
      }),
    }),

    updateProperty: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, propertyId }) => ({
        url: `${END_POINTS.property}/${propertyId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getPropertiesCsv: builder.query<
      CommonResponseType & {
        data: any;
      },
      GetCsvParams
    >({
      query: ({ type, preference, areaId }) => ({
        url:
          END_POINTS.getPropertyExportCsv +
          "?type=" +
          type +
          "&preference=" +
          preference +
          "&areaId=" +
          areaId,

        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetPropertiesQuery,
  useLazyGetPropertyByIdQuery,
  useDeletePropertyMutation,
  useLazyGetAreaQuery,
  useLazyGetParentAreaQuery,
  usePostAddPropertyMutation,
  useUpdatePropertyMutation,
  useLazyGetPropertiesCsvQuery,
} = propertyApi;
