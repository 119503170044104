import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import { Pie } from "react-chartjs-2";
import { showError } from "../../constants";
import { useLazyGetUserGraphQuery } from "../../services/main";
import useTranslation from "../../hooks/Translation";
ChartJS.register(ArcElement, Tooltip, Legend);

const Analytics = () => {
  const [getUserGraphQuery] = useLazyGetUserGraphQuery();
  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [userGraph, setUserGraph] = useState<any>([]);
  const translate=useTranslation();
  const getUserGraph = async () => {
    try {
      const response = await getUserGraphQuery({
        type: userFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserGraph(response?.data?.userCount);
      } else {
        setUserGraph([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleOrderChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const names = userGraph?.map((item: any) => item._id);
  const values = userGraph?.map((item: any) => item.count);

  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: translate?.dashboard?.Earning,
        data: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        borderColor: "#151D30",
        backgroundColor: "#151D30",
      },
    ],
  };

  const graphUsersData = {
    labels: names,
    datasets: [
      {
        label: translate?.dashboard?.User,
        data: values,
        borderColor: "#151D30",
        backgroundColor: "#151D30",
      },
    ],
  };
  const piechartData = {
    labels: ["SaverLite", "SaverPro", "SaverElite"],
    datasets: [
      {
        label: "",
        data: [50, 50, 30],
        backgroundColor: ["#72cedd", "#1c6975", "#051140"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    getUserGraph();
  }, [userFilter]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ReportsandAnalytics}</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
               {translate?.main?.TotalUsers}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleOrderChange}
                  >
                    <MenuItem value="" disabled>
                    {translate?.dashboard?.Select}
                    </MenuItem>
                    <MenuItem value="hourly"> {translate?.dashboard?.Hourly}</MenuItem>
                    <MenuItem value="weekly"> {translate?.dashboard?.Weekly}</MenuItem>
                    <MenuItem value="monthly"> {translate?.dashboard?.Monthly}</MenuItem>
                    <MenuItem value="yearly"> {translate?.dashboard?.Yearly}</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
               {translate?.main?.TotalEarnings}
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>   {translate?.dashboard?.Select}</option>
                    <option value={10}> {translate?.dashboard?.Hourly}</option>
                    <option value={20}>   {translate?.dashboard?.Weekly}</option>
                    <option value={30}> {translate?.dashboard?.Monthly}</option>
                    <option value={40}> {translate?.dashboard?.Yearly}</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">Popular Plans</h2>
              <Pie
                data={piechartData}
                style={{ height: "300px", width: "300px" }}
              />
            </div>
          </Grid> */}
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
