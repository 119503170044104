import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { usePostAddNotificationMutation } from "../../services/main";
import { showError, showToast } from "../../constants";
import useTranslation from "../../hooks/Translation";

const AddNotification = () => {
  const navigate = useNavigate();
  const [addNotification] = usePostAddNotificationMutation();
  const [receiver, setReceiver] = useState<string>("All");
  const translate = useTranslation();
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      message: "",
      title_ar: "",
      message_ar: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      message: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        title: formik?.values?.title,
        message: formik.values.message,
        title_ar: formik.values.title_ar,
        message_ar: formik.values.message_ar,
        type: receiver,
        notificationType: "Broadcast",
      };
      console.log(data, "DATAA");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await addNotification(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Notification sent successfully");
          formik.resetForm();
          navigate("/manage-notifications");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{translate?.main?.AddNotification}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.NotificationTitle}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.Title}
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.NotificationTitle} (arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.Title}
                      className="text_field"
                      onBlur={formik.handleBlur}
                      value={formik.values.title_ar}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.title_ar && formik.errors.title_ar
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.notification} {translate?.main?.message}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.message}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.notification} {translate?.main?.message}{" "}
                      (arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message_ar"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.message}
                      onBlur={formik.handleBlur}
                      value={formik.values.message_ar}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.message_ar && formik.errors.message_ar
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.SelectReceiver}
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          {translate?.dashboard?.Select}
                        </MenuItem>
                        <MenuItem value="All">{translate?.main?.All}</MenuItem>
                        <MenuItem value="User">
                          {translate?.dashboard?.User}
                        </MenuItem>
                        <MenuItem value="Agent">
                          {translate?.agents?.Agent}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
