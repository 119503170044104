import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../services/users";
import { AllUser, CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

const AgentsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getUserData] = useLazyGetUserByIdQuery();
  const [editUser] = useUpdateUserMutation();
  const [useDetails, setUserDetails] = useState<AllUser>();
  const [age, setAge] = useState("");
  const [phoneCode, setPhoneCode] = useState("+965");
  const [countryName, setCountryName] = useState("KW");
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const translate=useTranslation();
  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getUserData({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setUserDetails(response?.data);
        setImage(response?.data?.image);
        setPhoneCode(response?.data?.countryCode);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: useDetails?.email || "",
      fullName: useDetails?.fullName || "",
      phone: useDetails?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      fullName: Yup.string()
        .required("First name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      // address: Yup.string()
      //   .required("Address is required")
      //   .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const data = {
        email: formik.values.email,
        fullName: formik.values.fullName,
        countryCode: phoneCode,
        phone: formik.values.phone,
        image: image,
        // address: formik.values.address,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await editUser({
          encryptedBody,
          userId: `${id}`,
        }).unwrap();
        showToast(response?.message || "User Updated Successfully");
        navigate("/manage-agents");
      } catch (error: any) {
        console.log(error);
        showError(error);
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setCountryName(country?.countryCode);
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files, "event.target.files");

      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  useEffect(() => {
    getUserDetailById(id);
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">  {translate?.main?.EditAgentProfile}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-agents");
              }}
            >
               {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">{translate?.main?.Image}</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">{translate?.main?.FullName}</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      placeholder="First Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">{translate?.main?.Email}</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.email && formik.errors.email}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translate?.main?.PhoneNumber}
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      value={phoneCode + formik.values.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AgentsForm;
