import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Container, Typography } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";

import { Button, InputField } from "../../components";
import { Colors } from "../../assets";
import useTranslation from "../../hooks/Translation";
import { usePostForgotPasswordMutation } from "../../services/auth";
import { Loader, showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const ForgotPassword = () => {
  const [forgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();
  const Translation = useTranslation() as any;
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let data = {
        email: formik.values.email,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await forgotPasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            email: formik.values.email,
          };
          navigate("/verify-otp", { state: data });
          showToast("Otp sent successfully");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        ></Box>
        <div className="auth_box">
          <div className="form_div">
            <Box sx={{ mb: 2, textAlign: "center", color: Colors.primary }}>
              <Typography variant="h5">
                {Translation.auth.forgotPassword}
              </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ width: "100%" }}>
                <Typography className="custom_label">
                  {Translation.auth.emailId}
                </Typography>
                <InputField
                  name="email"
                  placeholder={Translation.auth.emailId}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <Box sx={{ mb: 2 }} />
              </FormControl>
              <Box sx={{ pt: 3 }}>
                <Button value={Translation.globals.submit} />
              </Box>
            </form>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
