// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useLazyGetCategoriesQuery,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  usePostAddCategoryMutation,
} from "../../services/main";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";
const AddCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [category, setCategory] = useState<any>([]);
  const [getCategories, { isLoading }] = useLazyGetCategoriesQuery();
  const [addCategoryMutation] = usePostAddCategoryMutation();
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [getCategoryDetails] = useLazyGetCategoryByIdQuery();
  const [updateCategory] = useUpdateCategoryMutation();
  const [categoryById, setCategoryById] = useState<any>();
  const translate = useTranslation();

  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res = await getCategoryDetails({ categoryId: id }).unwrap();
      if (res?.statusCode === 200) {
        setCategoryById(res?.data);
        setImage(res?.data?.image);
        setSelectedCategory(res?.data?.parent?._id);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategories({
        query: "",
        page: 1,
        type: 1,
        categoryId: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.category || []);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: categoryById?.name || "",
      title_ar: categoryById?.name_ar || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      title_ar: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        name: formik?.values?.title,
        name_ar: formik?.values?.title_ar,
        image: image,
        parent: selectedCategory,
      };

      if (id && id !== "add") {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateCategory({
            userId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("sub-category updated successfully");
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addCategoryMutation(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-category Added Successfully");
            formik.resetForm();
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetail(id);
    }
  }, []);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Sub-category</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Category
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {category?.length
                          ? category?.map((item: any) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {!category?._id && !category ? (
                        <h6 className="err_msg">Please select category</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Sub-category Name ( English )
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Sub-category Name ( English )"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Sub-category Name ( Arabic )
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Sub-category Name ( Arabic )"
                      onBlur={formik.handleBlur}
                      value={formik.values.title_ar}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.title_ar && formik.errors.title_ar
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
