import { END_POINTS } from "../constants/url";
import { Cms, CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type UpdateCmsParams = {
  userId: string | undefined;
  encryptedBody: CommonBody;
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type UpdateCategoryParams = {
  courseId: string | undefined;
  body: {
    title: string;
    description: string;
    coverImage: string;
    price: number;
    // merchant: "65548dad148334fa16a161a5";
    category: string;
    pricingType: number;
    videos: [];
  };
};

type PostCmsResponse = {
  message: string;
  statusCode: number;
  data: {
    createdAt: string;
    email: string;
    isDeleted: boolean;
    legal: string;
    phone: string;
    privacyPolicy: string;
    support: string;
    updatedAt: string;
    _id: string;
  }[];
};

type PostCmsBody = {
  email: string;
  phone: string;
  privacyPolicy: string;
  legal: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<CommonResponseType & { data: Cms }, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),

    postCms: builder.mutation<PostCmsResponse, UpdateCmsParams>({
      query: ({ userId, encryptedBody }) => ({
        url: `${END_POINTS.cms}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const { useLazyGetCmsQuery, usePostCmsMutation } = cmsApi;
