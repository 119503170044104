import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import "../../pages/users/Users.scss";
import { useLazyGetPaymentByIdQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import { Payments } from "../../types/General";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const PaymentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [paymentDetails] = useLazyGetPaymentByIdQuery();
  const [loading, setLoading] = useState<boolean>();
  const [paymentData, setPaymentData] = useState<Payments>();
  const translate=useTranslation();
  const getPaymentById = async (id: string | undefined) => {
    setLoading(true);
    try {
      const response = await paymentDetails({ paymentId: id }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setPaymentData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getPaymentById(id);
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.PaymentDetails}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-payments");
            }}
          >
            {translate?.main?.Back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translate?.main?.Name}</Typography>
                      <Typography component="p">
                        {paymentData?.user?.fullName || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translate?.main?.Email}</Typography>
                      <Typography component="p">
                        {paymentData?.user?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {paymentData?.subscription ? (
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">
                        {translate?.main?.SubscriptionPlan}
                        </Typography>
                        <Typography component="p">
                          {paymentData?.subscription?.planName}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translate?.main?.PaymentMode}</Typography>
                      <Typography component="p">
                        {paymentData?.paymentMode || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translate?.main?.Amount}</Typography>
                      <Typography component="p">
                        {paymentData?.amount || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                     {translate?.main?.PlanDetails}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">{translate?.main?.PlanName}</Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Type</Typography>
                          <Typography component="p">1 Month</Typography>
                        </Box>
                      </Grid> */}
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">{translate?.main?.PlanPrice}</Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}></Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Post For Sell" {...a11yProps(0)} />
              <Tab label="Post For Auction" {...a11yProps(1)} />
              <Tab label="Earnings" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <UserPostForSell></UserPostForSell>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <UserPostForAuction></UserPostForAuction>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UserEarnings></UserEarnings>
            </CustomTabPanel>
          </Box>
        </Card> */}
      </div>
    </MainContainer>
  );
};

export default PaymentDetails;
