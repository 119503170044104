import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import MainContainer from "../../layout/MainContainer";
import {
  useDeleteLabelMutation,
  useDeleteMyNotificationMutation,
  useLazyGetLabelsQuery,
  usePostAddLabelMutation,
} from "../../services/main";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageLabels = () => {
  const userData = useAuth();
  const [deleteLabel] = useDeleteLabelMutation();
  const [addLabel] = usePostAddLabelMutation();
  const [getLabels] = useLazyGetLabelsQuery();
  const [labelData, setLabelData] = useState<any>();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);
  const translate=useTranslation();
  const getAllLabels = async () => {
    setLoading(true);
    try {
      const response = await getLabels({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setFeatures(response?.data?.label || []);
      } else {
        setLabelData([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteLabel({
        labelId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Label deleted successfully");
        getAllLabels();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const [features, setFeatures] = useState<any>([
    { id: 1, label: "", lang: { en: "", ar: "" } },
  ]);
  console.log(features);
  const handleAddFeature = () => {
    const newFeatures = [
      ...features,
      { id: features?.length, label: "", lang: { en: "", ar: "" } },
    ];
    setFeatures(newFeatures);
  };

  const handleAddLabels = async () => {
    const data = {
      labelArray: features,
    };
    console.log(data);

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await addLabel(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        showToast("Label added successfully");

        // getUsers();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  };

  const handleRemoveFeature = (index: number, id: string) => {
    const updatedFeatures = features.filter((_: any, i: any) => i !== index);
    setFeatures(updatedFeatures);
    handleDelete(id);
  };

  // const handleFeatureChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   index: number
  // ) => {
  //   const updatedFeatures = features.map((feature, i) => {
  //     if (i === index) {
  //       return { ...feature, value: event.target.value };
  //     }
  //     return feature;
  //   });
  //   setFeatures(updatedFeatures);
  // };

  const handleFeatureChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    lang: string
  ) => {
    const { name, value } = event.target;
    const updatedFeatures = features.map((feature: any, i: any) => {
      if (i === index) {
        return {
          ...feature,
          [name]: lang === "label" ? value : feature[name],
          lang: {
            ...feature.lang,
            [lang]: lang === "label" ? feature.lang[lang] : value,
          },
        };
      }
      return feature;
    });
    setFeatures(updatedFeatures);
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage Labels");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getAllLabels();
  }, []);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Labels</h1>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={12}>
              <Typography className="custom_title" component="h5">
                Add Labels
              </Typography>
              <Grid
                container
                direction="column"
                alignItems="start"
                rowSpacing={1}
                columnSpacing={2}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <Box>
                    {features.map((feature: any, index: any) => (
                      <Grid item key={feature.id}>
                        <div className="feature_input">
                          <TextField
                            hiddenLabel
                            type="text"
                            name={`label`}
                            variant="outlined"
                            fullWidth
                            placeholder={`Key ${index + 1}`}
                            value={feature.label}
                            onChange={(event) =>
                              handleFeatureChange(event, index, "label")
                            }
                          />

                          <TextField
                            hiddenLabel
                            type="text"
                            name={`en`}
                            variant="outlined"
                            fullWidth
                            placeholder={`English`}
                            value={feature.lang.en}
                            onChange={(event) =>
                              handleFeatureChange(event, index, "en")
                            }
                          />

                          <TextField
                            hiddenLabel
                            type="text"
                            name={`ar`}
                            variant="outlined"
                            fullWidth
                            placeholder={`Arabic`}
                            value={feature.lang.ar}
                            onChange={(event) =>
                              handleFeatureChange(event, index, "ar")
                            }
                          />
                          {index === features.length - 1 ? (
                            <Button
                              className="btn"
                              onClick={() =>
                                handleRemoveFeature(index, feature?._id)
                              }
                            >
                              <RemoveIcon />
                            </Button>
                          ) : (
                            <Button
                              className="btn"
                              onClick={() =>
                                handleRemoveFeature(index, feature?._id)
                              }
                            >
                              <RemoveIcon />
                            </Button>
                          )}
                        </div>
                      </Grid>
                    ))}
                  </Box>
                  <Button className="add_btn" onClick={handleAddFeature}>
                    <AddIcon />
                  </Button>
                </Grid>
                <div className="form_btn" style={{ paddingLeft: 18 }}>
                  {hidePermission?.isAdd ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => {
                        handleAddLabels();
                      }}
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageLabels;
