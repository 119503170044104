import { END_POINTS } from "../constants/url";
import { AllUser, CommonBody } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalCount: number;
    count: number;
    users: AllUser[];
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
  type: string;
};

type UpdateUserParams = {
  userId: string | undefined;
  encryptedBody: CommonBody;
};

type PutBlockUserParams = {
  userId: string;
  encryptedBody: CommonBody;
};

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<CommonResponseType, GetUserParams>({
      query: ({ page, query, type }) => ({
        url:
          END_POINTS.user +
          "?search=" +
          query +
          "&page=" +
          page +
          "&type=" +
          type,
        method: "GET",
      }),
    }),

    getUserById: builder.query<
      CommonResponseType & { data: AllUser },
      { user_id: string | undefined }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.getUsers}/${user_id}`,
        method: "GET",
      }),
    }),

    changeUserStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.updateUser}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteUser: builder.mutation<CommonResponseType, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.deleteUser}/${userId}`,
        method: "DELETE",
      }),
    }),

    updateUser: builder.mutation<CommonResponseType, UpdateUserParams>({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.updateUser}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getUserCsv: builder.query<any, { type: string; query: string }>({
      query: ({ type, query }) => ({
        url: END_POINTS.exportUser + "?type=" + type + "&search=" + query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllUsersQuery,
  useLazyGetUserByIdQuery,
  useChangeUserStatusMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useLazyGetUserCsvQuery,
} = userApi;
