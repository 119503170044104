import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SIDEBAR_WIDTH } from "../constants";
import QuizIcon from "@mui/icons-material/Quiz";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SavingsIcon from "@mui/icons-material/Savings";
import PaymentIcon from "@mui/icons-material/Payment";
import BarChartIcon from "@mui/icons-material/BarChart";
import LabelIcon from "@mui/icons-material/Label";
import CategoryIcon from "@mui/icons-material/Category";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import useAuth from "../hooks/useAuth";
import MapIcon from "@mui/icons-material/Map";
import useTranslation from "../hooks/Translation";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const userData = useAuth();
  const navigate = useNavigate();
const translate=useTranslation();
  const [permission, setPermission] = useState("");
  console.log(permission);

  const handleNavigate = () => {
    if (list.find((item: any) => item?.name !== "Dashboard")) {
      navigate("/dashboard");
    }
  };

  const items = [
    {
      name: translate?.sideBar?.dashboard,
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      name: translate?.sideBar?.user,
      icon: <PersonAddIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      name: translate?.sideBar?.agent,
      icon: <PeopleIcon />,
      key: "3",
      selected: "/manage-agents",
    },
    {
      name: translate?.sideBar?.subAdmin,
      icon: <PeopleIcon />,
      key: "4",
      selected: "/manage-subAdmin",
    },
    {
      name: translate?.sideBar?.plans,
      icon: <SubscriptionsIcon />,
      key: "5",
      selected: "/manage-subscription",
    },
    {
      name:translate?.sideBar?.category,
      icon: <CategoryIcon />,
      key: "6",
      selected: "/manage-categories",
    },
    {
      name: translate?.sideBar?.property,
      icon: <MapsHomeWorkIcon />,
      key: "7",
      selected: "/manage-properties",
    },
    {
      name: translate?.sideBar?.areas,
      icon: <MapIcon />,
      key: "8",
      selected: "/manage-areas",
    },
    // {
    //   name: "Manage Labels",
    //   icon: <LabelIcon />,
    //   key: "8",
    //   selected: "/manage-labels",
    // },
    {
      name: translate?.sideBar?.tutorial,
      icon: <PhotoCameraFrontIcon />,
      key: "9",
      selected: "/manage-tutorials",
    },
    {
      name:translate?.sideBar?.payments,
      icon: <PaymentIcon />,
      key: "10",
      selected: "/manage-payments",
    },
    {
      name: translate?.sideBar?.notifications,
      icon: <NotificationsActiveIcon />,
      key: "11",
      selected: "/manage-notifications",
    },

    {
      name: translate?.sideBar?.analytics,
      icon: <BarChartIcon />,
      key: "12",
      selected: "/analytics",
    },
    {
      name: translate?.sideBar?.setting,
      icon: <SummarizeTwoToneIcon />,
      key: "13",
      selected: "/cms",
    },
    {
      name:translate?.sideBar?.faqs,
      icon: <BarChartIcon />,
      key: "14",
      selected: "/faq",
    },
  ];

  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permission
  );

  console.log(list, "-0-0-0");

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permission?.length) {
      const permission = userData?.permission;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.name === item?.name);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            right: 0,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>

          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
            right: 0,
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      // className="lst-itm"
                      // selected={
                      //   location.pathname.includes(item.selected) === item.selected
                      // }
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
        {/* {drawer} */}
      </Drawer>
    </Box>
  );
}
