import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  message: string;
  data: string;
};
type PostSetPasswordBody = {
  password: string;
};
type PostSetPasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type PostChangePasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    getUser: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.getUser,
        method: "GET",
      }),
    }),

    postForgotPassword: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.forgetPassword,
        method: "POST",
        body,
      }),
    }),

    postVerifyOtp: builder.mutation<PostVerifyOtpResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),

    editProfile: builder.mutation<
      { statusCode: number; data: User; message: string },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),

    postResetPassword: builder.mutation<PostSetPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),

    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  useLazyGetUserQuery,
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
  useEditProfileMutation,
  usePostResetPasswordMutation,
  usePostChangePasswordMutation,
} = authApi;
