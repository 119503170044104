// @ts-nocheck
import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import {
  getFromStorage,
  removeFromStorage,
  showError,
  SIDEBAR_WIDTH,
  STORAGE_KEYS,
  setToStorage,
} from "../constants";
import { useLazyGetUserQuery } from "../services/auth";
import { useAppDispatch } from "../hooks/store";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./Layout.scss";
import LanguageIcon from "@mui/icons-material/Language";
import useAuth from "../hooks/useAuth";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { resetAuth, setCredentials } from "../reducers/authSlice";
import { useLazyGetMyNotificationsQuery } from "../services/main";
import moment from "moment";
import { useLanguageContext } from "../context/Language";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const navigate = useNavigate();
  const [myNotification] = useLazyGetMyNotificationsQuery();
  const [myNotificationData, setMyNotificationData] = useState<any>();
  const style = generateResponsiveStyle();
  const dispatch = useAppDispatch();
  const user = useAuth();
  const [getUser] = useLazyGetUserQuery();
  const [openModal, setOpenModal] = useState(false);
  const { language, changeLanguage } = useLanguageContext();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const open1 = Boolean(anchorEl1);
  const openLang = Boolean(anchorElLang);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMyNotifications = async () => {
    try {
      const response = await myNotification({
        query: "",
        page: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMyNotificationData(response?.data?.notification || []);
        // setTotalCount(response?.data?.count);
      } else {
        setMyNotificationData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element

    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";

      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };

  const handleLangChange = (val: string) => {
    setTimeout(() => {
      // Perform other actions after the page is reloaded
      window.location.reload();
      setToStorage(STORAGE_KEYS.language, val);
      changeLanguage(val);
      setAnchorElLang(null);
    }, 500);
  };

  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token) {
      dispatch(
        setCredentials({
          user: null,
          token: token,
        })
      );
    }
  }, []);

  useEffect(() => {
    getMyNotifications();
  }, []);

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);
    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
    }
    getUserDetails();
  }, []);

  const getLogoutUser = async () => {
    removeFromStorage(STORAGE_KEYS.token);
    dispatch(resetAuth());
    navigate("/", { replace: true });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <Box className="container top_bar">
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: getLang === "en" ? `${280}px` : 0 },
          mr: { lg: getLang === "ar" ? `${280}px` : 0 },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            style={{ backgroundColor: "#72cedd" }}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                mr: getLang === "en" ? 2 : 0,
                ml: getLang === "ar" ? 2 : 0,
              }}
            >
              <IconButton
                className="notification_btn"
                aria-controls={openLang ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={openLang ? "true" : undefined}
                onClick={handleClickLang}
              >
                <LanguageIcon />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorElLang}
                open={openLang}
                onClose={handleCloseLang}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem value="en" onClick={(e) => handleLangChange("en")}>
                  English
                </MenuItem>
                <MenuItem value="ar" onClick={(e) => handleLangChange("ar")}>
                  Arabic
                </MenuItem>
              </Menu>
            </Box>
            <Box
              sx={{
                mr: getLang === "en" ? 2 : 0,
                ml: getLang === "ar" ? 2 : 0,
              }}
            >
              <IconButton
                className="notification_btn"
                aria-controls={open1 ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                <NotificationsActiveIcon />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="noti_head">
                  <Typography component="h2">Notifications</Typography>
                  <Button
                    className="btn btn_primary sm"
                    onClick={() => navigate("/recieved-notifications")}
                  >
                    See All
                  </Button>
                </Box>
                <ul className="noti_list">
                  {myNotificationData?.length > 0
                    ? myNotificationData?.slice(0, 3)?.map((item: any) => (
                        <li key={item?.user?.fullName}>
                          <figure>
                            <img
                              src={
                                item?.user?.image
                                  ? item?.user?.image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                          </figure>

                          <div className="noti_info" style={{ width: "100%" }}>
                            <Typography component="h3">
                              {item?.user?.fullName}
                              <Typography component="span">
                                {moment(new Date(item?.createdAt).getTime())
                                  .startOf("h:mm")
                                  .fromNow()}
                              </Typography>
                            </Typography>
                            <Typography component="p">
                              {item?.message}
                            </Typography>
                          </div>
                        </li>
                      ))
                    : ""}
                </ul>
              </Menu>
            </Box>

            <Box>
              <IconButton
                className="topbar_user_btn"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  style={{
                    marginRight: getLang === "en" ? 8 : 0,
                    marginLeft: getLang === "ar" ? 8 : 0,
                  }}
                  src={
                    user?.image
                      ? user?.image
                      : "/static/images/profile_placeholder.jpg"
                  }
                  alt=""
                />
                <span>{`${user?.firstName || "Admin"} ${
                  user?.lastName || ""
                }`}</span>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                className="userDropdown_menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/profile")}
                >
                  <IconButton className="drpdwn_icns">
                    <PersonIcon />
                  </IconButton>
                  My Profile
                </MenuItem>
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/changepassword")}
                >
                  <IconButton className="drpdwn_icns">
                    <LockResetIcon />
                  </IconButton>
                  Change Password
                </MenuItem>

                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => setOpenModal(true)}
                >
                  <IconButton className="drpdwn_icns">
                    <LogoutIcon />
                  </IconButton>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px", color: "black" }}
          >
            Are you sure, you want to logout?
          </Typography>
          <div className="flexdiv">
            <Button
              sx={{ color: "black", border: "1px solid black" }}
              className="yesBtn"
              onClick={() => {
                setOpenModal(false);
                getLogoutUser();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{
                color: "black",
                border: "1px solid black",
                marginLeft: 2,
              }}
              onClick={() => setOpenModal(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

export default Topbar;
