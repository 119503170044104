/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import MainContainer from "../../layout/MainContainer";
import { Card } from "@mui/material";
import useTranslation from "../../hooks/Translation";

const PermissionDenied = () => {
  const translate=useTranslation();
  return (
    <MainContainer>
      <div className="main_loyout">
        <Card>
          <div className="no_permissions">
            {/* <figure>
              <img src="/static/images/padLock.png" alt="" />
            </figure> */}
            <h3>Insufficient Permissions</h3>
            <p>
              You do not have necessary permissions required to access this
              view. For access please contact admin.
            </p>
          </div>
        </Card>
      </div>
    </MainContainer>
  );
};

export default PermissionDenied;
