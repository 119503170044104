import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetPropertiesQuery,
  useDeletePropertyMutation,
  useLazyGetAreaQuery,
  useLazyGetPropertiesCsvQuery,
} from "../../services/property";
import { Loader, showError, showToast } from "../../constants";
import { Property } from "../../types/General";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageProperties = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getPropertiesQuery, { isLoading }] = useLazyGetPropertiesQuery();
  const [propertyCsv] = useLazyGetPropertiesCsvQuery();
  const [getAreaQuery] = useLazyGetAreaQuery();
  const [deleteProperty] = useDeletePropertyMutation();
  const [receiver, setReceiver] = useState<string>("");
  const translate=useTranslation();
  const [receiver1, setReceiver1] = useState<string>("");
  const [receiver2, setReceiver2] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allProperty, setAllProperty] = useState<Property[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [allArea, setAllArea] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
    setReceiver1("");
    setReceiver2("");
  };

  const handleReceiverChange1 = (event: SelectChangeEvent) => {
    setReceiver1(event.target.value as string);
    setReceiver("");
    setReceiver2("");
  };

  const handleReceiverChange2 = (event: SelectChangeEvent) => {
    setReceiver2(event.target.value as string);
    setReceiver("");
    setReceiver1("");
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getProperties = async () => {
    try {
      const response = await getPropertiesQuery({
        query: debouncedSearchTerm.trim(),
        page: page,
        type: Number(receiver),
        preference: Number(receiver1),
        areaId: selectedArea,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllProperty(response?.data?.property || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllProperty([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getArea = async () => {
    try {
      const response = await getAreaQuery({
        page: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllArea(response?.data || []);
      } else {
        setAllArea([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleClearFilters = () => {
    setReceiver("");
    setReceiver1("");
    setSelectedArea("");
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteProperty({ propertyId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Property deleted successfully");
        getProperties();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedArea(event.target.value as string);
  };

  const getPropertyCsv = async () => {
    try {
      const response = await propertyCsv({
        query: debouncedSearchTerm.trim(),
        type: Number(receiver),
        preference: Number(receiver1),
        areaId: selectedArea,
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Properties"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getProperties();
  }, [receiver, receiver1, debouncedSearchTerm, selectedArea, page]);

  useEffect(() => {
    getArea();
  }, []);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManageProperties}</h1>
        </div>
        <Card className="cards">
          <div className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={getPropertyCsv}>
                <FileDownloadIcon />  {translate?.dashboard?.ExportCsv}
              </Button>
              {hidePermission?.isAdd ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-properties/add")}
                >
                {translate?.main?.Add}
                </Button>
              ) : null}
            </Box>
          </div>

          <div
            style={{
              display: "flex",
              gap: 10,
              paddingTop: 20,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,

                width: "70%",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={receiver}
                  // multiple
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleReceiverChange}
                >
                  <MenuItem value="" disabled>
                  {translate?.main?.All}
                  </MenuItem>
                  <MenuItem value="1"> {translate?.main?.PropertiesforSale}</MenuItem>
                  <MenuItem value="2"> {translate?.main?.PropertiesforRent}</MenuItem>
                  <MenuItem value="3"> {translate?.main?.PropertiesforExchange}</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={receiver1}
                  // multiple
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleReceiverChange1}
                >
                  <MenuItem value="" disabled>
                  {translate?.main?.UserPreferences}
                  </MenuItem>
                  <MenuItem value="1"> {translate?.main?.MostViewedProperties}</MenuItem>
                  <MenuItem value="2">  {translate?.main?.MostSearchedProperties}</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  className="select_div"
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedArea}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="" disabled>
                 {translate?.main?.Mostsearchedbyarea}
                  </MenuItem>
                  {allArea?.area?.length
                    ? allArea?.area?.map((item: any) => (
                        <MenuItem key={item?._id} value={item?._id}>
                          {item?.name || ""}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {!allArea?._id && !allArea ? (
                  <h6 className="err_msg">{translate?.main?.Pleaseselectcategory}</h6>
                ) : (
                  ""
                )}
              </FormControl>
            </div>
            <div
              style={{ width: "30%", alignItems: "flex-end", display: "flex" }}
            >
              {receiver !== "" || receiver1 !== "" || selectedArea !== "" ? (
                <Button className="btn" onClick={() => handleClearFilters()}>
                  Clear Filters
                </Button>
              ) : null}
            </div>
          </div>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translate?.main?.SNo}</TableCell>

                  <TableCell>{translate?.main?.propertyName}</TableCell>
                  <TableCell>{translate?.main?.PropertyCategory}</TableCell>
                  <TableCell>{translate?.main?.AgentName}</TableCell>
                  <TableCell>{translate?.main?.CustomerName}</TableCell>
                  <TableCell>{translate?.main?.Location}</TableCell>
                  <TableCell>{translate?.main?.Type}</TableCell>
                  <TableCell>{translate?.main?.Action}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allProperty?.length > 0 ? (
                  allProperty?.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{`${row?.subCategoryId?.name} for ${row?.categoryId?.name} in ${row?.subAreaId?.name}`}</TableCell>
                      <TableCell>{row?.categoryId?.name || ""}</TableCell>
                      <TableCell>{row?.agentName || "-"}</TableCell>
                      <TableCell>
                        {row?.userId?.fullName || "By Admin"}
                      </TableCell>
                      <TableCell>{row?.subAreaId?.name || "-"}</TableCell>
                      <TableCell>
                        {row?.listingType == 1
                          ? "Normal"
                          : row?.listingType == 2
                          ? "Highlighted"
                          : "Agent"}
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-properties/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {hidePermission?.isAdd ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-properties/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : null}
                          {hidePermission?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                       {translate?.main?.NoPropertiesFound}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {allProperty?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={allProperty}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Property"
      />
    </MainContainer>
  );
};

export default ManageProperties;
