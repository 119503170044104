// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useLazyGetCategoriesQuery,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  usePostAddCategoryMutation,
} from "../../services/main";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import {
  useLazyGetAreaByIdQuery,
  usePostAddAreaMutation,
  useUpdateAreaMutation,
} from "../../services/area";
import useTranslation from "../../hooks/Translation";

const AddAreas = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [category, setCategory] = useState<any>([]);
  const [getCategories, { isLoading }] = useLazyGetCategoriesQuery();
  const [addArea] = usePostAddAreaMutation();
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [getAreaDetails] = useLazyGetAreaByIdQuery();
  const [updateArea] = useUpdateAreaMutation();
  const [areaById, setAreaById] = useState<any>();
  const translate=useTranslation();
  const getAreaDetail = async (id: string | undefined) => {
    try {
      const res = await getAreaDetails({ areaId: id }).unwrap();
      if (res?.statusCode === 200) {
        setAreaById(res?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  //   const getCategory = async () => {
  //     try {
  //       const response = await getCategories({
  //         query: "",
  //         page: 1,
  //         type: 1,
  //         categoryId: "",
  //       }).unwrap();
  //       if (response?.statusCode === 200) {
  //         setCategory(response?.data?.category || []);
  //       } else {
  //         setCategory([]);
  //       }
  //     } catch (error: any) {
  //       showError(error?.data?.message || "");
  //     }
  //   };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: areaById?.name || "",
      title_ar: areaById?.name_ar || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      title_ar: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        name: formik?.values?.title,
        name_ar: formik?.values?.title_ar,
      };

      if (id && id !== "add") {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateArea({
            userId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Governorate updated successfully");
            navigate("/manage-areas");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addArea(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Governorate Added Successfully");
            formik.resetForm();
            navigate("/manage-areas");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  useEffect(() => {
    if (id && id !== "add") {
      getAreaDetail(id);
    }
  }, []);

  //   useEffect(() => {
  //     getAreaDetail();
  //   }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1> {translate?.main?.Add}  {translate?.main?.Governorate} </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-areas");
              }}
            >
              {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Category
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {category?.length
                          ? category?.map((item: any) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {!category?._id && !category ? (
                        <h6 className="err_msg">Please select category</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid> */}

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translate?.main?.Governorate}   {translate?.main?.Name}   {translate?.main?.English}  
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Governorate Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translate?.main?.Governorate}   {translate?.main?.Name}   {translate?.main?.Arabic}  
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Governorate Name ( Arabic )"
                      onBlur={formik.handleBlur}
                      value={formik.values.title_ar}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.title_ar && formik.errors.title_ar
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                   {translate?.main?.Save}    
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddAreas;
