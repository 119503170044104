import { ReactNode, useState } from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./SideBar";
import Topbar from "./TopBar";
import { getFromStorage, SIDEBAR_WIDTH, STORAGE_KEYS } from "../constants";
import "./Layout.scss";

function MainContainer({
  children,
  hideHeader = false,
}: {
  children: ReactNode;
  hideHeader?: boolean;
}) {
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar handleSidebarToggle={handleSidebarToggle} />
      <Sidebar
        mobileOpen={mobileOpen}
        handleSidebarToggle={handleSidebarToggle}
      />
      <Box
        className="dashboard_main mn-lyout"
        component="main"
        sx={{
          marginLeft: getLang === "en" ? "auto" : "unset",
          marginRight: getLang === "ar" ? "auto" : "unset",
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        }}
      >
        <Container className="sub-layout" sx={{ paddingTop: 13 }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
export default MainContainer;
