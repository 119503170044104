import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC6wm3CimyPRBBPXf0jqyZIkiVWOiA2REE",
  authDomain: "maskan-8b7c7.firebaseapp.com",
  projectId: "maskan-8b7c7",
  storageBucket: "maskan-8b7c7.appspot.com",
  messagingSenderId: "428974404333",
  appId: "1:428974404333:web:0042fc87624e9e603092e4",
  measurementId: "G-H79T3RSVGD",
};

const app = initializeApp(firebaseConfig);
export default app;
