import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useDeleteMyNotificationMutation,
  useLazyGetMyNotificationsQuery,
} from "../../services/main";
import { showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useTranslation from "../../hooks/Translation";

const RecievedNotifications = () => {
  const [deleteMyNotification] = useDeleteMyNotificationMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [myNotification] = useLazyGetMyNotificationsQuery();
  const [myNotificationData, setMyNotificationData] = useState<any>();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const translate=useTranslation();
  const getMyNotifications = async () => {
    try {
      const response = await myNotification({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMyNotificationData(response?.data?.notification || []);
        setTotalCount(response?.data?.notificationCount);
      } else {
        setMyNotificationData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteMyNotification({
        notificationId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Notification deleted successfully");
        getMyNotifications();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];

  useEffect(() => {
    getMyNotifications();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myNotificationData?.length > 0
                  ? myNotificationData.map((row: any, i: any) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row?.user?.fullName || "-"}</TableCell>
                        <TableCell>{row?.user?.email || "-"}</TableCell>
                        <TableCell>{row?.title || "-"}</TableCell>
                        <TableCell>{row?.message || "-"}</TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {myNotificationData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={myNotificationData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(selectedId);
        }}
        name="Notification"
      />
    </MainContainer>
  );
};

export default RecievedNotifications;
