import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, InputField } from "../../../components";
import { Colors } from "../../../assets";
import { usePostResetPasswordMutation } from "../../../services/auth";
import { temporaryToken } from "../../../reducers/authSlice";
import {
  getFromStorage,
  removeFromStorage,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../../constants";
import { useAppDispatch } from "../../../hooks/store";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordMutation] = usePostResetPasswordMutation();
  const tempToken = getFromStorage(STORAGE_KEYS.tempToken);

  console.log(tempToken, "sdfdsfsfd");

  const dispatch = useAppDispatch();

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let data = {
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await resetPasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Password reset successfully" || "");
          removeFromStorage(STORAGE_KEYS.tempToken);
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );

          navigate("/", {
            replace: true,
          });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        ></Box>
        <div className="auth_box">
          <div className="form_div">
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 2, textAlign: "center", color: Colors.primary }}>
                <Typography variant="h5">Reset Password</Typography>
              </Box>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">Password</Typography>
                <InputField
                  placeholder="Password"
                  name="newPassword"
                  password
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                />
              </FormControl>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">
                  Confirm Password
                </Typography>
                <InputField
                  placeholder="Confirm Password"
                  name="passwordConfirmation"
                  password
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                />
              </FormControl>
              <Box sx={{ pt: 3 }}>
                <Button value="Submit" />
              </Box>
            </form>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default ResetPassword;
