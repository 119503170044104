import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, InputField } from "../../../components";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { Colors } from "../../../assets";
import { showError, showToast } from "../../../constants";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [changePasswordMutation] = usePostChangePasswordMutation();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setOldPassword(!oldPassword);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setCnfmPassword(!cnfmPassword);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let data = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await changePasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Password changed successfully" || "");
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        ></Box>
        <div className="auth_box">
          <div className="logo_div">
            <IconButton
              onClick={() => navigate("/dashboard")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1c6975" }}
            >
              <ArrowBackIcon />
            </IconButton>
            {/* <figure className="logo_cntnr">
              <img src="/static/images/logogif.gif" alt="" />
            </figure> */}
          </div>
          <div className="form_div">
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 2, textAlign: "center", color: Colors.primary }}>
                <Typography variant="h5">Change Password</Typography>
              </Box>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">Old Password</Typography>
                <InputField
                  placeholder="Old Password"
                  password
                  name="oldPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  helperText={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                />
              </FormControl>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">Password</Typography>
                <InputField
                  placeholder="New Password"
                  password
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                />
              </FormControl>
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">
                  Confirm Password
                </Typography>
                <InputField
                  placeholder="Confirm Password"
                  password
                  name="passwordConfirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                />
              </FormControl>
              <Box sx={{ pt: 3 }}>
                <Button value="Submit" />
              </Box>
            </form>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default ChangePassword;
