import { END_POINTS } from "../constants/url";
import { CommonBody, Subscription } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
};

type AddSubscriptionBody = {
  name: string;
  image: string;
  description: string;
  monthlyPrice: string;
  quarterlyPrice: string;
  yearlyPrice: string;
  features: any;
};

type PutBlockParams = {
  subsId: string;
  encryptedBody: CommonBody;
};

type UpdateParams = {
  subsId: string | undefined;
  encryptedBody: CommonBody;
};

export const subscriptionApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          membership: Subscription[];
        };
      },
      GetUserParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.membership + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    addSubscription: builder.mutation<
      { message: string; statusCode: number; data: Subscription },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.membership,
        method: "POST",
        body,
      }),
    }),

    getSubscriptionById: builder.query<
      CommonResponseType & { data: Subscription },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.membership}/${subId}`,
        method: "GET",
      }),
    }),

    changeSubscriptionStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockParams
    >({
      query: ({ encryptedBody, subsId }) => ({
        url: `${END_POINTS.membership}/${subsId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteSubscription: builder.mutation<
      CommonResponseType,
      { subsId: string }
    >({
      query: ({ subsId }) => ({
        url: `${END_POINTS.membership}/${subsId}`,
        method: "DELETE",
      }),
    }),

    updateSubscription: builder.mutation<
      CommonResponseType & { data: Subscription[] },
      UpdateParams
    >({
      query: ({ encryptedBody, subsId }) => ({
        url: `${END_POINTS.membership}/${subsId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const {
  useLazyGetAllSubscriptionsQuery,
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
  useUpdateSubscriptionMutation,
} = subscriptionApi;
