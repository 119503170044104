import LocalizedStrings from "react-localization";
import { useLanguageContext } from "../context/Language";
import localization from "../utils/localization";

export default function useTranslation() {
  const { language } = useLanguageContext() as any;
  const translation = new LocalizedStrings(localization);

  translation.setLanguage(language);
  return translation;
}
